import { get, sum } from "lodash"
import { useLocalization } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { useSurgicalRecords } from "hooks/useSurgicalRecords"
import { NoTranslationFound } from "components/NoTranslationFound"

/**
 * surgical records モデルのデータをテーブルにする
 * デザイン変更やテーブル自体なくなるかもしれない
 */
export const SurgicalRecordsTableTemplate = () => {
  const { label, records } = useSurgicalRecords()
  const { locale } = useLocalization()
  const { t } = useTranslation()

  const localizedRecords = records?.nodes
    .filter(node => node.node_locale.slice(0, 2) === locale)
    .sort((a, b) => (a.yearString < b.yearString ? -1 : 1))

  const localizedLabels = label.nodes
    .filter(node => node.node_locale.slice(0, 2) === locale)
    .sort((a, b) => (a.yearString < b.yearString ? -1 : 1))

  const heads = localizedRecords.map(record =>
    t("{{year}} 年度", { year: record.yearString }),
  )

  const dataMap = localizedLabels.map(lbl => ({
    label: lbl.label,
    caseId: lbl.caseId,
    data: records?.nodes
      .filter(node => node.node_locale.slice(0, 2) === locale)
      .sort((a, b) => (a.yearString < b.yearString ? -1 : 1))
      .map(node => ({
        year: node.yearString,
        count: get(node, lbl.caseId),
      })),
  }))

  if (dataMap.find(data => data.label === null) !== undefined) {
    // 手術項目のラベル必ず多言語されている
    throw new Error("null includes in any labels")
  }

  const allLabels = localizedLabels.map(node => node.caseId)
  const totalNumberOfCases = localizedRecords.map(node => ({
    year: node.yearString,
    count: sum(allLabels.map(label => get(node, label))),
  }))

  return dataMap.length > 0 ? (
    <div className="department_block">
      <div className="general_box inviewfadeInUp">
        <h2>{t("年間手術実績", "年間手術実績")}</h2>
        <div className="general_part inviewfadeInUp">
          <h3>{t("過去３年間の手術件数", "過去３年間の手術件数")}</h3>
          <div className="reserve_table surgery">
            <table className="tbl-r03 usually_table illness">
              <thead>
                <tr>
                  <th></th>
                  {heads.map(head => (
                    <th key={head}>{head}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataMap.map(dm => (
                  <tr key={dm.caseId}>
                    <td className="name">{dm.label}</td>
                    {dm.data.map(dt => (
                      <td key={dt.year}>
                        {t("{{count}} 件", {
                          count: dt.count,
                        })}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr className="m_total">
                  <td className="name rl_bb">{t("合計手術件数")}</td>
                  {totalNumberOfCases.map(total => (
                    <td key={total.year}>
                      {t("{{count}} 件", {
                        count: total.count,
                      })}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <NoTranslationFound />
  )
}
