import { useStaticQuery, graphql } from "gatsby"

/**
 * 手術実績
 * データとラベルなどの情報を別に取得して結合する
 */
export const useSurgicalRecords = () => {
  const surgicalRecords = useStaticQuery(
    graphql`
      query SurgicalRecords {
        records: allContentfulSurgicalRecord(
          sort: { fields: yearString, order: DESC }
          filter: { show: { eq: true } }
        ) {
          nodes {
            case1
            case2
            case3
            case4
            case5
            case6
            show
            yearString
            node_locale
          }
        }
        label: allContentfulSurgicalRecordLabel(
          sort: { fields: caseId, order: ASC }
        ) {
          nodes {
            caseId
            label
            color
            node_locale
          }
        }
      }
    `,
  )
  return surgicalRecords
}
