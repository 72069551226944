import React from "react"
import { Helmet } from "react-helmet"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import Footer from "components/Footer"
import { PageTopAnchor } from "components/PageTopAnchor"
import { PcFooterLink } from "components/FooterLink/Pc"
import { MoFooterLink } from "components/FooterLink/Mo"
import { MoFooterWrap } from "components/FooterWrap"
import { PhoneArea } from "components/PhoneArea"
import { BannerArea } from "components/BannerArea"
import { OurHospitalNavi } from "components/OurHospitalNavi"
import { SurgicalRecordsChartTemplate } from "templates/surgicalRecordsChart"
import { SurgicalRecordsTableTemplate } from "templates/surgicalRecordsTable"

const IndexPage = () => {
  const { t } = useTranslation()

  return (
    <main>
      <Helmet title={t("手術実績", "手術実績")} />
      <div className="sub_page">
        <section className="page_header service inviewfadeInUp">
          <div className="container2">
            <h1>{t("手術実績", "手術実績")}</h1>
          </div>
        </section>

        <div className="container2">
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to="/">{t("星井眼科医院TOP", "星井眼科医院TOP")}</Link>
              </li>
              <li>
                <Link to="/our_hospital_menu">
                  {t("当院について", "当院について")}
                </Link>
              </li>
              <li>{t("手術実績", "手術実績")}</li>
            </ul>
          </section>

          <section className="content">
            <div className="main_area inviewfadeInUp">
              <SurgicalRecordsTableTemplate />

              <div className="general_box inviewfadeInUp">
                <div className="general_part">
                  <h3>{t("手術件数グラフ", "手術件数グラフ")}</h3>
                  <div className="chart-container">
                    <SurgicalRecordsChartTemplate />
                  </div>
                </div>
              </div>
            </div>

            <OurHospitalNavi />
          </section>
        </div>
      </div>
      <div className="container2">
        <section className="content">
          <PhoneArea />
          <BannerArea />
        </section>
      </div>
      <section className="content">
        <div className="footer_link inviewfadeInUp">
          <PcFooterLink />
          <MoFooterLink />
        </div>
      </section>

      <Footer />
      <PageTopAnchor />
      <MoFooterWrap />
    </main>
  )
}

export default IndexPage
