import { Bar } from "react-chartjs-2"
import { get } from "lodash"
import { useLocalization } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { useSurgicalRecords } from "hooks/useSurgicalRecords"
import { NoTranslationFound } from "components/NoTranslationFound"

/**
 * 手術実績の Bar chart を表示
 */
export const SurgicalRecordsChartTemplate = () => {
  const { label, records } = useSurgicalRecords()
  const { locale } = useLocalization()
  const { t } = useTranslation()

  const localizedRecords = records?.nodes.filter(
    node => node.node_locale.slice(0, 2) === locale,
  )
  const localizedLabels = label.nodes.filter(
    node => node.node_locale.slice(0, 2) === locale,
  )

  const labels = localizedRecords.map(record =>
    t("{{year}} 年", { year: record.yearString }),
  )
  const datasets = localizedLabels.map(lbl => ({
    caseid: lbl.caseId,
    label: lbl.label,
    data: localizedRecords.map(node => get(node, lbl.caseId)), // NOTE: caseId が一致するデータ [8, 7, 3]
    backgroundColor: lbl.color,
    stack: "stack-1",
  }))

  const data = { labels, datasets }
  const graphOption = { indexAxis: "y", responsive: true }

  return localizedLabels.length > 0 ? (
    <Bar data={data} options={graphOption} />
  ) : (
    <NoTranslationFound />
  )
}
